/*
 * MOTION DESIGN LTD CONFIDENTIAL
 *
 * [2023] Motion Design Ltd All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Motion Design Ltd. The intellectual and technical concepts contained
 * herein are proprietary to Motion Design Ltd. and may be covered by N.Z.
 * and Foreign Patents, patents in process, and are protected by trade secret
 * or copyright law. Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written permission
 * is obtained from Motion Design Ltd.
 */

import classNames from 'classnames'
import React from 'react'
import Select, {ClassNamesConfig, GroupBase, Props} from 'react-select'
import CreatableSelect from 'react-select/creatable'

export type MDSelectProps<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group> & {
    label?: string
    horizontal?: boolean
    creatable?: boolean
}

export const MDSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    label,
    horizontal,
    creatable,
    className,
    ...props
}: MDSelectProps<Option, IsMulti, Group>) => {
    const SelectComponent = creatable ? CreatableSelect : Select
    return (
        <div className={classNames(className, horizontal && 'flex items-center')}>
            {!!label && (
                <label htmlFor={props.id} className={classNames(horizontal ? 'mr-3' : 'mb-1')}>
                    {label}
                </label>
            )}
            <SelectComponent classNames={selectClasses} {...props} />
        </div>
    )
}

export const selectClasses: ClassNamesConfig<any, boolean, any> | undefined = {
    control: ({isDisabled, isFocused}) =>
        classNames(
            'rounded-md transition-outline',
            isFocused ? 'border-sky-500 !outline !outline-[3px] shadow-none !outline-sky-100' : 'border-gray-300',
        ),
    placeholder: () => classNames('text-gray-400'),
    dropdownIndicator: () => '!p-[7px]',
    valueContainer: () => '!py-0',
    option: ({isDisabled, isFocused, isSelected}) =>
        classNames(
            isSelected && 'bg-sky-500',
            !isSelected && isFocused && 'bg-sky-100 text-sky-500',
            !isDisabled && isSelected && 'active:bg-sky-500',
            !isDisabled && !isSelected && 'active:bg-sky-100',
        ),
}
